@font-face {
  font-family: 'Satoshi-Light';
  src: url('assets/fonts/Satoshi-Light.woff2') format('woff2'),
       url('assets/fonts/Satoshi-Light.woff') format('woff'),
       url('assets/fonts/Satoshi-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Regular';
  src: url('assets/fonts/Satoshi-Regular.woff2') format('woff2'),
       url('assets/fonts/Satoshi-Regular.woff') format('woff'),
       url('assets/fonts/Satoshi-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Medium';
  src: url('assets/fonts/Satoshi-Medium.woff2') format('woff2'),
       url('assets/fonts/Satoshi-Medium.woff') format('woff'),
       url('assets/fonts/Satoshi-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Bold';
  src: url('assets/fonts/Satoshi-Bold.woff2') format('woff2'),
       url('assets/fonts/Satoshi-Bold.woff') format('woff'),
       url('assets/fonts/Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}


@font-face {
  font-family: 'Satoshi-Black';
  src: url('assets/fonts/Satoshi-Black.woff2') format('woff2'),
       url('assets/fonts/Satoshi-Black.woff') format('woff'),
       url('assets/fonts/Satoshi-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}

* {
  box-sizing: border-box;
}
body {
  font-family: 'Satoshi-Regular';
  line-height: 1.5;
}
img{
  max-width: 100%;
}

@keyframes up {
  0%{
    transform: translateY(10px);
  }
  50%{
    transform: translateY(5px);
  }
  100%{
    transform: translateY(10px);
  }
}
@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    -ms-transform: translateY(-2000px);
    transform: translateY(-2000px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

html{
  scroll-behavior: smooth;
}
a{
  text-decoration: none;
}
#root{
  overflow: hidden;
}